import React from 'react';
import './Video.css';

const Video = () => {
  return (
    <div className="video-container">
      <video
        className="video"
        autoPlay
        loop
        muted
        playsInline
        webkit-playsinline
        preload="metadata"
        crossorigin="anonymous"
      >
        <source
          src="https://www.magdamokrzan.pl/videos/portfolio.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
