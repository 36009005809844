import React from 'react';
import { useTranslation } from 'react-i18next';
import Contact from './components/Contact';
import SocialMedia from './components/SocialMedia';
import Video from './components/Video';
import './App.css';

function App() {
  const { t } = useTranslation();

  return (
    <div className="main">
      <Video />
      <div className="description">
        <h1 className="title">{t('title')}</h1>
        <Contact />
        <SocialMedia />
      </div>
    </div>
  );
}

export default App;
