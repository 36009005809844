import React from 'react';
import { Trans } from 'react-i18next';
import './Contact.css';

function Contact() {
  return (
    <p className="contact">
      <Trans i18nKey="contactMessage">
        Say hi on
        <a
          href="https://www.instagram.com/magdamokrzan/"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-link"
        >
          Instagram
        </a>
        <a
          href="https://www.linkedin.com/in/magda-mokrzan-702195210/"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-link"
        >
          LinkedIn
        </a>
        <a href="mailto:mokrzanmagda@gmail.com" className="contact-link">
          mokrzanmagda@gmail.com
        </a>
      </Trans>
    </p>
  );
}

export default Contact;
