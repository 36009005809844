import React from 'react';
import { useTranslation } from 'react-i18next';
import './SocialMedia.css';

function SocialMedia() {
  const { t } = useTranslation();

  return (
    <ul className="social-media-box">
      <li>
        <a
          href="https://www.kickstarter.com/projects/magdamokrzan/fonciary?ref=section-homepage-projectcollection-8-distance&category_id=Q2F0ZWdvcnktNDU="
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Kickstarter website"
          className="social-media-link"
        >
          {t('kickstarter')}
        </a>
      </li>
      <li>
        <a
          href="https://medium.com/@mokrzanmagda"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Medium website"
          className="social-media-link"
        >
          {t('medium')}
        </a>
      </li>
      <li>
        <a
          href="https://asp-gda.academia.edu/MagdaMokrzan"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Academic website"
          className="social-media-link"
        >
          {t('academic')}
        </a>
      </li>
    </ul>
  );
}

export default SocialMedia;
